export const projects = [
  {
    id: 1,
    title: "Space Adventure",
    description: "Unity ile geliştirilmiş 2D uzay macera oyunu. Galakside hayatta kalma mücadelesi",
    image: "/images/space-game.jpg",
    technologies: ["Unity", "C#", "2D Graphics"]
  },
  {
    id: 2,
    title: "Dungeon Crawler",
    description: "Unreal Engine ile geliştirilmiş 3D aksiyon RPG. Karanlık zindanlarda macera",
    image: "/images/dungeon-game.jpg",
    technologies: ["Unreal Engine", "C++", "3D Modeling"]
  },
  {
    id: 3,
    title: "Puzzle Master",
    description: "Unity ile geliştirilmiş bulmaca oyunu. Zeka ve mantık soruları",
    image: "/images/puzzle-game.jpg",
    technologies: ["Unity", "C#", "UI Design"]
  },
  {
    id: 4,
    title: "Racing Fever",
    description: "Unreal Engine ile geliştirilmiş yarış oyunu. Gerçekçi fizik motoru",
    image: "/images/racing-game.jpg",
    technologies: ["Unreal Engine", "C++", "Physics"]
  },
  {
    id: 5,
    title: "Survival Island",
    description: "Unity ile geliştirilmiş hayatta kalma oyunu. Açık dünya macerası",
    image: "/images/survival-game.jpg",
    technologies: ["Unity", "C#", "AI"]
  },
  {
    id: 6,
    title: "Medieval Wars",
    description: "Unreal Engine ile geliştirilmiş strateji oyunu. Krallıklar arası savaş",
    image: "/images/strategy-game.jpg",
    technologies: ["Unreal Engine", "C++", "Multiplayer"]
  },
  {
    id: 7,
    title: "Zombie Defense",
    description: "Unity ile geliştirilmiş tower defense oyunu. Zombi istilasına karşı savunma",
    image: "/images/zombie-game.jpg",
    technologies: ["Unity", "C#", "Pathfinding"]
  },
  {
    id: 8,
    title: "Sports League",
    description: "Unreal Engine ile geliştirilmiş spor simülasyonu. Gerçekçi fizik ve animasyonlar",
    image: "/images/sports-game.jpg",
    technologies: ["Unreal Engine", "C++", "Animation"]
  },
  {
    id: 9,
    title: "City Builder",
    description: "Unity ile geliştirilmiş şehir inşa etme oyunu. Ekonomi ve kaynak yönetimi",
    image: "/images/city-game.jpg",
    technologies: ["Unity", "C#", "Economy System"]
  },
  {
    id: 10,
    title: "VR Experience",
    description: "Unreal Engine ile geliştirilmiş sanal gerçeklik deneyimi. İnteraktif ortam",
    image: "/images/vr-game.jpg",
    technologies: ["Unreal Engine", "C++", "VR"]
  }
]; 