import { FaLinkedin, FaGithub, FaInstagram, FaXTwitter, FaSteam } from 'react-icons/fa6';

export const socialLinks = [
  {
    url: "https://linkedin.com/in/nurionacak",
    icon: FaLinkedin,
    color: "hover:text-blue-500"
  },
  {
    url: "https://github.com/IRUNBOSS",
    icon: FaGithub,
    color: "hover:text-gray-300"
  },
  {
    url: "https://instagram.com/nurionacak",
    icon: FaInstagram,
    color: "hover:text-pink-500"
  },
  {
    url: "https://twitter.com/nurionacak",
    icon: FaXTwitter,
    color: "hover:text-blue-400"
  },
  {
    url: "https://steamcommunity.com/id/IRUNBOSS/",
    icon: FaSteam,
    color: "hover:text-blue-600"
  }
]; 